<template>
  <div class="topbar" @click="onClick">
    <div class="topbar-left">
      <img src="@/assets/logo.png" alt="" />
    </div>
    <div class="topbar-content">
      Open the YowantTV App for more wonderful short dramas
    </div>
    <div class="topbar-right">
      <div class="btn">OPEN</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopBarDownloadBlock",
  props: {
    onClick: {
      type: Function,
      require: true,
      default: () => {

      }
    }
  },
};
</script>

<style lang="less" scoped>
.topbar {
  width: 100%;
  padding: 8px 12px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #373737;
  border-bottom: 4px solid #e1452e;

  &-left {
    display: block;
    padding-right: 10px;
    flex-shrink: 0;

    img {
      display: inline-block;
      width: 44px;
      height: 44px;
    }
  }

  &-content {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    color: #ffffff;
  }

  &-right {
    flex-shrink: 0;

    .btn {
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      padding: 6px 13px 7px 13px;
      font-variation-settings: "opsz" auto;
      color: #ffffff;

      border-radius: 16.5px;
      opacity: 1;
      background: linear-gradient(180deg,
          #ff5b43 0%,
          rgba(255, 91, 67, 0.49) 100%);
    }
  }
}
</style>