import request from './request';

// doc: http://47.96.40.190:8088/api/swagger-ui/#/


// 取首页推荐短剧信息，只取一条
const geth5IndexBox = async() => {
    return request.post('/api/shortDramaXiaoyDramaInfo/geth5IndexBox');
};

// 短剧排行列表基本信息表，剧名、多少集、几集开始付费列表分页，分面信息放接口后面：?pageNum=1&pageSize=2
const geth5RankList = async(params) => {
    return request.post('/api/shortDramaXiaoyDramaInfo/geth5RankList', {
        params
    });
};

// 短剧分集表，每部剧的单集的播放地址等信息列表分页,分页信息放接口后面：?pageNum=1&pageSize=2&tvid=89933
const getH5listPage = async(params) => {
    return request.post('/api/shortDramaXiaoySeriesInfo/h5listPage', {
        params
    });
};

//获取剧情信息
const getDramaInfo = async(params) => {
    return request.post('/api/shortDramaXiaoyDramaInfo/geth5DramaInfo', {
        params
    });
};

//tvid
// sid
// mode
// middlemanId
// inviteCode
const h5PvLog = async(params) => {
    return request.post('/api/h5PvLog/save', {
        params
    });
};

// 1、打开播放页的时候，把url上的ttclid和？后面的内容上报
// 2. 落地页按钮点击 -> Download，上报Download事件
// {
//     "tvid": 0,取到上传
//     "sid": 0,取到上传
//     "ttclid": "string",  取到上传 // ttclid
//     "eventname": "string",    // 'paly' | 'Download'
//     "urlstring": "string" ？后面的上传
//      middlemanId: "string" ？后面的上传
//      inviteCode: "string" ？后面的上传
//   }
// const h5EventLogChanel = async(params) => {
//     return request.post('/api/h5EventLog/saveChannel', {
//         params
//     });
// };
const h5EventLogChanel = async(params) => {
    return request.post('/api/h5EventLog/saveTTChannel', {
        params
    });
};

// 1、打开播放页的时候，把url上的fbclick和？后面的内容上报
// {
//     "tvid": 0,取到上传
//     "sid": 0,取到上传
//     "fbclick": "string",  取到上传 // fbclick
//     "campaign": "string",
//     "campaignid": "string", 取到上传
//     "eventname": "string",    // 'Leed' | 'ViewContent'
//      middlemanId: "string" ？后面的上传
//      inviteCode: "string" ？后面的上传
//   }
const h5FbEventLogChanel = async(params) => {
    return request.post('/api/h5EventLog/saveFBChannel', {
        params
    });
};
export default {
    geth5IndexBox,
    geth5RankList,
    getH5listPage,
    getDramaInfo,
    h5PvLog,
    h5EventLogChanel,
    h5FbEventLogChanel
}