import Vue from "vue";
import VueRouter from "vue-router";

import List from "./pages/List.vue";
import Detail from "./pages/Detail.vue";

Vue.use(VueRouter);

const routes = [{
        path: "/",
        component: List,
    },
    {
        path: "/detail",
        component: Detail,
    },
];

const router = new VueRouter({
    mode: "history", // 或者 'hash'
    routes,
});

export default router;