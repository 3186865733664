<template>
    <div class="TopBarAdBlock">
        <div class="TopBarAdBlock-content">
            <h1>banner广告</h1>
            <!-- <amp-ad width="100vw" height="320" type="adsense" data-ad-client="ca-pub-8560296254348782"
                data-ad-slot="7212946772" data-auto-format="rspv" data-full-width="">
                <div overflow=""></div>
            </amp-ad> -->
            <InArticleAdsense data-ad-client="ca-pub-8560296254348782" data-ad-slot="7212946772"></InArticleAdsense>
        </div>       
    </div>
</template>

<script>
export default {
    name: 'TopBarAdBlock',
    props: {
        onClick: {
            type: Function,
            default: () => { },
        },
        onClose: {
            type: Function,
            default: () => { },
        },
    },
    created() {      
    },
    data() {
        return {}
    },
    methods: {       
        handleClose() {
            if (this.cancleClose) {
                this.onClose();
            }
        }
    },
}
</script>

<style lang="less" scoped>
.TopBarAdBlock {
    width: 100%;
    height: 66px;
    background: #212121;
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background: rgba(0, 0, 0, 0.8);

    color: #fff;

    &-content {
        width: 100%;
        margin: 0 auto;     

        display: inline-block;
        font-size: 16px;
        font-weight: normal;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0em;

        color: #FFFFFF;

    }
}
</style>